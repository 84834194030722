




















































import { Component, Prop, Vue } from "vue-property-decorator";
import GA from "@/app/modules/_global/classes/Analytics";

type Tile = {
  name: string;
};

@Component
export default class DocumentsComponent extends Vue {
  @Prop(Object) readonly tile!: Tile;

  constructor() {
    super();

    GA.addCustomDimension(1, `${this.userData?.user.id}`);
    GA.trackView(`Documents: ${this.tile?.name}`);
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  documentIcon(extension: string) {
    let icon = "mdi-file-outline";

    if (extension.match(/jpg|jpeg|png/g) != null) icon = "mdi-file-image-outline";
    if (extension.match(/pdf/g) != null) icon = "mdi-file-pdf-outline";

    return icon;
  }
}
